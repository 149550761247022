import { Ref, ref, computed } from "vue";
import { defineStore } from "pinia";
import { Account, AccountArray } from "@/services/account/types";
import { AccountService } from "@/services/account/AccountService";

export const accountStore = defineStore("account", () => {
  const account: Ref<AccountArray> = ref(new AccountArray());
  const activeAccount: Ref<number> = ref(
    +(localStorage.getItem("activeAccount") ?? 0)
  );

  const accountService = new AccountService();
  const promise: Promise<Response> = new Promise((res) => res);

  const accountSelected = computed(() => {
    return account.value.data?.find((a) => a.id == activeAccount.value);
  });
  
  const query = async (id: number) => {
    const { data } = await accountService.getAccountServices(id);

    if (data?.data) {
      account.value.data = data.data;

      const selected = localStorage.getItem("activeAccount");

      if (!selected) {
        setActiveAccount(account.value.data[0]?.id);
      }
    }
  };

  const setActiveAccount = (id: number) => {
    localStorage.setItem("activeAccount", `${id}`);
    activeAccount.value = id;
  };

  const getData = (state: any) => {
    return state.account;
  };

  return {
    account,
    activeAccount,

    accountService,
    accountSelected,
    promise,

    query,
    setActiveAccount,
    getData,
  };
});

export type AccountStore = ReturnType<typeof accountStore>;

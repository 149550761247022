import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import MyMovementsView from "@/views/MyMovementsView.vue"
import MyStocksView from "@/views/MyStocksView.vue"
import MyPerfilView from "@/views/MyPerfilView.vue"
import MyDividendsView from "@/views/MyDividendsView.vue"
import MyInvertionsView from "@/views/MyInvertionsView.vue"
import { AuthService } from '@/services/auth/AuthService'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'auth',
    component: () => import('@/views/AuthView.vue'),
    meta: { requiresAuth: false },
    redirect: { name: 'auth-login'},
    children: [
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('@/components/auth/AuthLogin.vue')
      },
      {
        path: 'forgot_password',
        name: 'auth-forgot-password',
        component: () => import('@/components/auth/AuthForgotPassword.vue')
      },
      
    ]
  },
  {
    path: '/reset-password/:tokenPassword',
    name: 'auth-forgot-password-return',
    meta: { requiresAuth: false },
    component: () => import('@/components/auth/AuthForgotPasswordReturn.vue')
  },
  {
    path: '/my_stocks',
    name: 'MyStocks',
    component: MyStocksView,
    meta: { requiresAuth: true }
  },
  {
    path: '/my_movements',
    name: 'MyMovements',
    component: MyMovementsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/my_dividends',
    name: 'MyDividends',
    component: MyDividendsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/my_perfil',
    name: 'MyPerfil',
    component: MyPerfilView,
    meta: { requiresAuth: true }
  },
  {
    path: '/my_invertions',
    name: 'Invertions',
    component: MyInvertionsView,
    meta: { requiresAuth: true }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: () => ({ name: "auth-forgot-password-return" }),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authService = new AuthService();
  const isAuthenticated = await authService.hasValidUserSession();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Esta ruta requiere autenticación
    if (!isAuthenticated) {
      // Si el usuario no está autenticado, redirige a la página de inicio de sesión
      next({ name: 'auth-login' });
    } else {
      // Si el usuario está autenticado, permite la navegación normal
      next();
    }
  } else {
    // Esta ruta no requiere autenticación
    if(isAuthenticated){
      next({name:"MyPerfil"});
    }else{
      next();
    }

    // Si el usuario no está autenticado, permite la navegación normal
  }
});

export default router

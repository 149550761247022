import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { Shareholder } from "@/services/shareholder/types";

export const useAuthStore = defineStore("auth", () => {
  const token = ref<string | null>(localStorage.getItem("token"));
  const user = ref<Shareholder | null>(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : null
  );

  const setToken = (newToken: string) => {
    token.value = newToken;
    localStorage.setItem("token", newToken);
  };

  const setUser = (newUser: Shareholder) => {
    user.value = newUser;
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  const logout = () => {
    token.value = null;
    user.value = null;
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("activeAccount");
  };

  const setUserId = (id: number) => {
    localStorage.setItem("user_id", String(id));
  };

  const userIsAuthenticated = computed(() => user.value !== null);

  const getUserId = () => localStorage.getItem("user_id");

  return {
    token,
    user,
    setToken,
    setUser,
    logout,
    setUserId,
    userIsAuthenticated,
    getUserId,
  };
});
import { getEnvConfig } from "@/configs/UrlConfig";
import { getResponse, keysToSnake } from "../utils";
import { Response } from "../types";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { Account,AccountArray, AccountSelectedClass, AccountCreateClass } from "./types";

export class AccountService extends AuthenticatedService {
    

    async getAccountServices(idUser: number): Promise<Response<AccountArray>> {
        const url = `${getEnvConfig().apiURL}/admin/user_accounts?user_id=${idUser}`

        return getResponse(url, this.token, 'GET', undefined, undefined)
    }

    async setAccount(
      user_id:number,
      accountCreate:AccountSelectedClass
    ): Promise<Response<Account>> {
      const url = `${getEnvConfig().apiURL
        }/admin/shareholders/account/selected?user_id=${user_id}`;
      const bodyAccountPostData =
        keysToSnake(accountCreate);
      return getResponse(
        url,
        this.token,
        "POST",
        bodyAccountPostData,
        undefined,
        Account
      );
    }
    
    async createNewAccount(
    accountPostData: AccountCreateClass
    ): Promise<Response<Account>> {
    const url = `${getEnvConfig().apiURL}/dividends/create`;
    const bodyAccountPostData = keysToSnake(accountPostData);
    return getResponse(
        url,
        this.token,
        "POST",
        bodyAccountPostData,
        undefined,
        Account
    );
    }

    async registerNewAccount(

        bank_code: number,
        bank_name: string,
        account_type: string,
        account_number: string,
        account_owner_name: string,
        account_owner_rut: string,
        account_email:string,
    
    ) {
        const data = {
            bank_code: bank_code,
            bank_name: bank_name,
            account_type: account_type,
            account_number: account_number,
            account_owner_name: account_owner_name,
            account_owner_rut: account_owner_rut,
            account_email:account_email,
            is_selected:0,
    
        };
        const url = `${getEnvConfig().apiURL}/admin/shareholders/account`;
    
        return getResponse(
        url,
        this.token,
        "POST",
        data,
        undefined,
        );
    }
    async changeInfo(user: Account): Promise<Response<null>>{
      const url = `${getEnvConfig().apiURL}/admin/shareholders/${user.id}/account`

      const data = {
          "bank_name": user.bankName,
          "account_type": user.accountType,
          "account_number": user.accountNumber,
          "account_owner_name": user.accountOwnerName,
          "account_owner_rut": user.accountOwnerRut,
          "account_email": user.accountEmail,
          "business_address": user.businessAddress,
          "business_name": user.businessName,
          "business_rut":user.businessRut,
          "writing_date":user.writingDate,
          "notary":user.notary,
          "notary_city":user.notaryCity,
          "repertoire_number":user.repertoireNumber,
          "first_name": user.firstName,
          "last_name": user.lastName,
          "rut": user.rut,
          "client_is_female": user.clientIsFemale,
          "total_shares_a": user.totalSharesA,
          "total_shares_b": user.totalSharesB,
          "county": user.county,
          "region": user.region,
          "email": user.email,
          "phone": user.phone,
          "address": "",
      }

      return getResponse(url, this.token, "PUT", data)
  }
      

}